import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import TeamMember from 'components/Team/Member';
import Columns from 'components/Columns';
import { Flex, Box } from 'CHAMELEON_REACT_HUB';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`A design system lives and dies by its usage by both developer and designers. For
this reason, we more than welcome active contributions.`}</p>
    <h2 {...{
      "id": "ways-to-contribute",
      "style": {
        "position": "relative"
      }
    }}>{`Ways to contribute`}</h2>
    <h3 {...{
      "id": "missing-options",
      "style": {
        "position": "relative"
      }
    }}>{`Missing options`}</h3>
    <p>{`A design system is a living organism that is constantly evolving. The need for
it to evolve always stems from a general need or a specific user request. If you
find a certain component not fitting your needs, be sure to report it. The more
uses cases Chameleon can cover, the more straightforward the
design-to-development workflow will become.`}</p>
    <h3 {...{
      "id": "counter-intuitiveness",
      "style": {
        "position": "relative"
      }
    }}>{`Counter-intuitiveness`}</h3>
    <p>{`If, while using Chameleon, you encounter something that's counter-intuitive,
don't hesitate to inform us about it. Chameleon is built to serve your needs, so
we want to tailor it to your expectations. This can range anywhere from the
naming of a prop or the needed combination of props to achieve your expected
result.`}</p>
    <h3 {...{
      "id": "sharing-patterns",
      "style": {
        "position": "relative"
      }
    }}>{`Sharing Patterns`}</h3>
    <p>{`As discussed in our vision about design system tiers, we support actively
sharing your own patterns, not only within your team but also cross-team. You
can always ping us if you need help with setting up your patters so that they
can easily be shared. We ourselves will always try to be on the lookout for
highly-used patterns that should be promoted to a higher tier, but you can
always proactively contact us about a pattern that you see fit!`}</p>
    <h2 {...{
      "id": "how-to-contact-us",
      "style": {
        "position": "relative"
      }
    }}>{`How to contact us`}</h2>
    <p>{`We use our internal Slack as our main communication-channels. We have several
general channels that each serve a specific purpose. Feel free to join the ones
that are applicable to you.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`#chameleon-general`}</strong>{` Catch-all for communications, major announcements and
generic questions related to Chameleon Design System`}</li>
    </ul>
    <p>{`Sometimes, we set up private channels related to specific projects. If you would
need access to a project-specific channel, don't hesitate to ping us.`}</p>
    <h2 {...{
      "id": "team",
      "style": {
        "position": "relative"
      }
    }}>{`Team`}</h2>
    <Flex justifyContent="space-between" flexDirection={{
      "xs": "column",
      "lg": "row"
    }} mdxType="Flex">
  <Box mb={4} style={{
        "flex": "1"
      }} mdxType="Box">
    <TeamMember name="Jens Van den Nieuwenhuysen" image={require('./img/jens.jpg')} position="UI & System designer" mdxType="TeamMember" />
  </Box>
  <Box mb={4} style={{
        "flex": "1"
      }} mdxType="Box">
    <TeamMember name="Ken Wuytack" image={require('./img/ken.jpg')} position="UI & System designer" mdxType="TeamMember" />
  </Box>
    </Flex>
    <Flex justifyContent="space-between" flexDirection={{
      "xs": "column",
      "lg": "row"
    }} mdxType="Flex">
  <Box mb={4} style={{
        "flex": "1"
      }} mdxType="Box">
    <TeamMember name="Thomas Mattheussen" image={require('./img/thomas.jpg')} position="Front-end Developer Web" mdxType="TeamMember" />
  </Box>
  <Box mb={4} style={{
        "flex": "1"
      }} mdxType="Box">
    <TeamMember name="Wannes De Backer" image={require('./img/wannes.jpg')} position="Front-end Developer Web" mdxType="TeamMember" />
  </Box>
    </Flex>
    <Flex justifyContent="space-between" flexDirection={{
      "xs": "column",
      "lg": "row"
    }} mdxType="Flex">
  <Box mb={4} style={{
        "flex": "1"
      }} mdxType="Box">
    <TeamMember name="Stijn Willems" image={require('./img/stijn.jpg')} position="Front-end Developer iOS & Android" mdxType="TeamMember" />
  </Box>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      